.vUWIFG_Map {
  width: 5rem;
  height: 5rem;
  z-index: 10;
  transform-origin: 100% 100%;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: .5rem;
  padding: .8rem;
  transition: all .2s;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  box-shadow: .1rem .1rem #000;
}

.vUWIFG_Map.vUWIFG_inactive {
  cursor: pointer;
}

.vUWIFG_Map.vUWIFG_active {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  bottom: 0;
  right: 0;
}

.vUWIFG_MapImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  outline: .1rem solid #000;
  position: relative;
}

.vUWIFG_Map.vUWIFG_active .vUWIFG_MapImage {
  width: 70vh;
  height: 70vh;
  border: .1rem solid #000;
  outline: none;
  box-shadow: .1rem .1rem #000;
}

.vUWIFG_Viewport {
  opacity: .5;
  background-color: #000;
  position: absolute;
}

.vUWIFG_Map.vUWIFG_active .vUWIFG_Viewport, .vUWIFG_Map.vUWIFG_inactive p {
  display: none;
}

.vUWIFG_Map.vUWIFG_active p {
  text-align: center;
  padding-top: 0;
  padding-bottom: 1rem;
  display: block;
}

.vUWIFG_Map.vUWIFG_active p svg {
  width: 10rem;
  vertical-align: middle;
  margin-right: .3rem;
  padding-bottom: .35rem;
  display: inline;
}

.vUWIFG_Map .vUWIFG_BackButton {
  display: none;
}

.vUWIFG_Map.vUWIFG_active .vUWIFG_BackButton {
  display: block;
  position: fixed;
  top: 1rem;
  left: 1rem;
}

@media only screen and (width >= 800px) {
  .vUWIFG_Map.vUWIFG_inactive:hover {
    width: 20rem;
    height: 20rem;
    border-radius: 1rem;
    padding: 2rem;
  }
}

@media screen and (orientation: portrait) {
  .vUWIFG_Map.vUWIFG_active .vUWIFG_MapImage {
    width: 70vw;
    height: 70vw;
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  .vUWIFG_Map {
    width: 8rem;
    height: 8rem;
    padding: 1.5rem;
  }
}

button {
  color: #fff;
  text-shadow: .1rem .1rem #000;
  cursor: pointer;
  background-color: #0077b6;
  border: .1rem solid #000;
  border-radius: .5rem;
  padding: 1rem 2rem;
  font-family: Itim;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all .1s;
  box-shadow: .1rem .1rem #000;
}

button:hover {
  background-color: #3c97dc;
}

button:active {
  transform: translate(.1rem, .1rem);
  box-shadow: 0 0 #000;
}

.fetWzG_Switch {
  text-align: center;
  transform-origin: bottom;
  margin: auto auto 1.5rem;
  transition: all .2s;
}

.fetWzG_Switch label {
  width: 3rem;
  height: 1.8rem;
  margin-top: .4rem;
  display: inline-block;
  position: relative;
}

.fetWzG_Label {
  font-size: 1.2rem;
}

.fetWzG_Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fetWzG_Slider {
  cursor: pointer;
  background-color: #ccc;
  border: .1rem solid #000;
  border-radius: 1.8rem;
  transition: all .2s;
  position: absolute;
  inset: 0;
  box-shadow: .1rem .1rem #000;
}

.fetWzG_Slider:before {
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: 50%;
  transition: all .2s;
  position: absolute;
  bottom: .1rem;
  left: .1rem;
}

input:checked + .fetWzG_Slider {
  background-color: #0077b6;
}

input:checked + .fetWzG_Slider:before {
  transform: translateX(1.2rem);
}

.JovIWG_Container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: scroll;
}

.JovIWG_Container::-webkit-scrollbar {
  display: none;
}

.JovIWG_Board {
  background-color: #000;
}

.JovIWG_Chunk {
  margin: 0;
  padding: 0;
  position: absolute;
}

.JovIWG_Chunk.JovIWG_loading {
  background: #eee;
}

.JovIWG_Tile {
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  border: 1px solid #000;
  transition: all .2s;
  position: absolute;
}

.JovIWG_Tile:not(.JovIWG_solved) {
  background-color: #0077b6;
}

.JovIWG_Tile:not(.JovIWG_locked, .JovIWG_solved) {
  cursor: pointer;
}

.JovIWG_Tile span {
  color: #fff;
  text-shadow: .1rem .1rem #000;
  opacity: .6;
  font-size: 1.3rem;
  transition: all .2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-20deg);
}

.JovIWG_Tile:not(.JovIWG_locked, .JovIWG_solved):hover {
  background-color: #3c97dc;
}

.JovIWG_Tile:not(.JovIWG_locked, .JovIWG_solved):hover span {
  text-shadow: .15rem .15rem #000;
  opacity: 1;
  transform: translate(-50%, -50%)scale(1.1);
}

.JovIWG_Tile.JovIWG_solved {
  image-rendering: pixelated;
}

.JovIWG_Tile.JovIWG_solved.JovIWG_loading {
  background: #eee;
}

.JovIWG_Tile.JovIWG_locked {
  opacity: .8;
}

.JovIWG_Tile.JovIWG_locked svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(.1rem .1rem #000);
}

@media only screen and (hover: none) and (pointer: coarse) {
  .JovIWG_Tile span {
    text-shadow: .2rem .2rem #000;
    font-size: 2.6rem;
  }

  .JovIWG_Tile:not(.JovIWG_locked, .JovIWG_solved):hover span {
    text-shadow: .3rem .3rem #000;
  }
}

.RyZqCW_Container {
  width: 100%;
  height: 100%;
  transform-origin: center;
  transition: all .1s;
  animation: .1s RyZqCW_opening;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes RyZqCW_opening {
  0% {
    transform: scale(1, 0);
  }

  100% {
    transform: scaleY(1);
  }
}

.RyZqCW_Container.RyZqCW_closing {
  transform: scaleY(0);
}

.RyZqCW_Game {
  z-index: 20;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: 1rem;
  padding: 3rem 4rem;
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: .1rem .1rem #000;
}

.RyZqCW_Game.RyZqCW_nonInteractive {
  pointer-events: none;
}

.RyZqCW_Game.RyZqCW_solved {
  transform: translate(-50%, -50%)rotateY(360deg);
}

.RyZqCW_Backdrop {
  width: 100%;
  height: 100%;
  z-index: 19;
  -webkit-backdrop-filter: blur(40px) brightness(120%) saturate(80%);
  backdrop-filter: blur(40px) brightness(120%) saturate(80%);
  position: absolute;
  top: 0;
  left: 0;
}

.RyZqCW_BackButton {
  text-align: center;
  margin: 2rem auto auto;
  display: block;
}

.RyZqCW_Grid {
  width: max-content;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-user-select: none;
  user-select: none;
  margin: 0 auto;
}

.RyZqCW_Grid tbody tr td {
  transition: all .1s;
}

.RyZqCW_RowHints {
  text-align: right;
  transform-origin: 100%;
  padding-right: .3rem;
}

.RyZqCW_RowHints.RyZqCW_hovered {
  transform: scaleX(1.05);
}

.RyZqCW_ColumnHints {
  text-align: center;
  vertical-align: bottom;
  transform-origin: bottom;
}

.RyZqCW_ColumnHints.RyZqCW_hovered {
  transform: scaleY(1.05);
}

.RyZqCW_RowHints.RyZqCW_solved, .RyZqCW_ColumnHints.RyZqCW_solved {
  color: #999;
  text-decoration: line-through;
}

.RyZqCW_RowHints.RyZqCW_hovered, .RyZqCW_ColumnHints.RyZqCW_hovered {
  font-weight: bolder;
}

.RyZqCW_HintsRow, .RyZqCW_Grid tbody:last-child {
  border-bottom: .1rem solid #000;
}

.RyZqCW_Grid tbody tr td:first-child, .RyZqCW_Grid tbody tr td:last-child {
  border-right: .1rem solid #000;
}

.RyZqCW_Cell {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #000;
}

.RyZqCW_Cell.RyZqCW_middleRow {
  border-bottom-width: .1rem;
}

.RyZqCW_Cell.RyZqCW_middleColumn {
  border-right-width: .1rem;
}

.RyZqCW_Cell.RyZqCW_hovered {
  background-color: #ddd;
}

.RyZqCW_Cell.RyZqCW_adjacent {
  background-color: #eee;
}

.RyZqCW_Cell.RyZqCW_marked {
  background-color: #0077b6;
}

.RyZqCW_Cell.RyZqCW_filled {
  background-color: #000;
}

.RyZqCW_Cell.RyZqCW_invalid {
  text-align: center;
  color: #555;
}

.RyZqCW_Mistakes {
  text-align: center;
  margin: auto;
  font-size: 1.2rem;
}

.RyZqCW_Mistakes p {
  margin-bottom: .6rem;
}

.RyZqCW_Mistake {
  width: 1rem;
  height: 1rem;
  border: .1rem solid #000;
  border-radius: 1rem;
  margin: 0 .2rem;
  transition: all .2s;
  display: inline-block;
  box-shadow: .1rem .1rem #000;
}

.RyZqCW_Mistake.RyZqCW_full {
  background-color: #ff5c5c;
}

.RyZqCW_Popup {
  z-index: 25;
  background-color: #ddd;
  border: .1rem solid #000;
  border-radius: 1rem;
  padding: 2rem 4rem;
  animation: .1s RyZqCW_popupOpen;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: .1rem .1rem #000;
}

@keyframes RyZqCW_popupOpen {
  0% {
    transform: translate(-50%, -50%)scale(1, 0);
  }

  100% {
    transform: translate(-50%, -50%)scale(1);
  }
}

.RyZqCW_Popup h1 {
  text-shadow: .1rem .1rem #aaa;
  text-align: center;
  margin-top: 0;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .RyZqCW_Game {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .RyZqCW_Mistakes {
    margin: 0;
  }

  .RyZqCW_Popup {
    width: max-content;
  }
}

._ezhwa_Backdrop {
  width: 100%;
  height: 100%;
  z-index: 30;
  -webkit-backdrop-filter: blur(40px) brightness(120%) saturate(80%);
  backdrop-filter: blur(40px) brightness(120%) saturate(80%);
  transform-origin: center;
  position: fixed;
  top: 0;
  left: 0;
}

._ezhwa_Backdrop._ezhwa_closing {
  animation: .1s _ezhwa_close;
}

@keyframes _ezhwa_close {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1, 0);
  }
}

._ezhwa_WelcomeDialog {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: 1rem;
  padding: 3rem 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: .1rem .1rem #000;
}

._ezhwa_WelcomeDialog p {
  margin: 2rem 0;
  line-height: 1.4rem;
}

._ezhwa_Info {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  border: .1rem solid #000;
  border-radius: 2rem;
  margin: 0 .3rem;
  font-weight: bold;
  display: inline-block;
  box-shadow: .1rem .1rem #000;
}

._ezhwa_CookieInfo {
  color: #777;
  font-size: .7rem;
  margin: 2rem 0 0 !important;
}

@media only screen and (hover: none) and (pointer: coarse) {
  ._ezhwa_WelcomeDialog {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    display: flex;
  }

  ._ezhwa_WelcomeDialog svg {
    width: 60vw;
  }

  ._ezhwa_WelcomeDialog p {
    margin: 2rem;
  }

  ._ezhwa_CookieInfo {
    margin: 2rem 2rem 0 !important;
  }
}

.IEr4yq_Info {
  transition: all .1s;
}

.IEr4yq_Info.IEr4yq_closed {
  z-index: 5;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: .1rem solid #000;
  border-radius: 50%;
  font-weight: bold;
  line-height: 1.5rem;
  display: block;
  position: fixed;
  top: 1rem;
  left: 1rem;
  box-shadow: .1rem .1rem #000;
}

.IEr4yq_Info.IEr4yq_closed button, .IEr4yq_Info.IEr4yq_closed .IEr4yq_Content {
  display: none;
}

.IEr4yq_Info.IEr4yq_opened {
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.IEr4yq_Info.IEr4yq_opened span {
  display: none;
}

.IEr4yq_Info.IEr4yq_opened button {
  display: block;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.IEr4yq_Content {
  width: 40%;
  min-height: 100vh;
  height: auto;
  text-align: justify;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 30%;
  display: flex;
}

.IEr4yq_Content svg {
  width: 10rem;
  vertical-align: middle;
  display: inline;
}

.IEr4yq_Copyright {
  text-align: center;
  margin-top: 3rem;
  line-height: 1.5rem;
}

@media only screen and (width >= 800px) {
  .IEr4yq_Info.IEr4yq_closed:hover {
    transform-origin: center;
    transform: scale(1.2);
  }
}

@media only screen and (hover: none) and (pointer: coarse) {
  .IEr4yq_Info.IEr4yq_closed {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  .IEr4yq_Content {
    width: 80%;
    margin: 4rem 10%;
    padding: 0;
  }

  .IEr4yq_Info.IEr4yq_opened button {
    text-align: center;
    margin: 1rem auto 0;
    position: relative;
    transform: translateX(-1rem);
  }
}

/*# sourceMappingURL=index.64c0bccb.css.map */
